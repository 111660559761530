.customTable .ant-table-thead .ant-table-cell {
  background-color: black !important;
  color: white !important;
  border-bottom: 1px solid #15aedb !important;
  white-space: nowrap;
}
.customTable .highlight-bottom-border > td {
  border-bottom: solid 1px green !important;
}

.customTable .ant-table-tbody > tr > td {
  background-color: black !important;
  border-bottom: 1px solid #15aedb !important;
  color: #15aedb;
  white-space: nowrap;
}
.customTable .ant-pagination-item-link {
  color: white !important
  ;
}

.customTable .ant-table-column-sorter {
  color: white !important;
}

.customTable .ant-table-cell::before {
  display: none;
}

.customTable .ant-pagination-item a {
  color: white !important;
}

.customTable .ant-pagination-item-active {
  background-color: black !important;
  border-color: #15aedb !important;
}
